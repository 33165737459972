/* eslint-disable no-unused-vars */

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BForm, BFormTextarea, BFormTimepicker,
  BPagination, BFormGroup, BFormRadioGroup, BFormSelect, VBPopover, BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import flatPickr from 'vue-flatpickr-component'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import bookingPrayers from '../BookingPrayers'
import bookingPrayersModule from '../BookingPrayersModule'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    flatPickr,
    BFormTextarea,
    BButton,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BFormTimepicker,
    BFormSelect,
    BPopover,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      langOptions: [
        { id: 'Arabic', name: 'Arabic' },
        { id: 'English', name: 'English' },
      ],
      options: [
        { text: 'Once', value: 'one_time' },
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Selected Dates', value: 'dates' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Both', value: 'both' },
      ],
      optionsSel: [
        { id: 'A', name: '15 Feb 2021 wed' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
        { id: 'B', name: '2021-12-12' },
      ],
      selected: null,
      multiDate: null,
      multiRang: null,
      settings: {
        centerMode: true,
        centerPadding: '20px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
      },
      occurrence: null,
    }
  },
  setup() {
    const EVENTS_MODULE = 'eventsModule'
    // Register module
    if (!store.hasModule(EVENTS_MODULE)) store.registerModule(EVENTS_MODULE, bookingPrayersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_MODULE)) store.unregisterModule(EVENTS_MODULE)
    })

    const {
      addTableColumns, events, addSession, addBooking, addEventsData, showBTN, showSession, addNewSession,
      showAddSession, returnedData, creatSession, activityId,
    } = bookingPrayers()

    return {
      addTableColumns,
      events,
      addSession,
      addBooking,
      addEventsData,
      showBTN,
      showSession,
      showAddSession,
      addNewSession,
      returnedData,
      creatSession,
      activityId,
    }
  },
}
